import styled, { keyframes } from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledHeader = styled.header`
	align-items: center;
	height: 60px;
	padding: 0 20px;

	display: none;

	@media screen and (min-width: ${CSSVars.breakpointMD}) {
		padding: 0 30px;
	}

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		display: flex;
	}

	&.dark {
		background-color: ${CSSVars.gray700};

		.login {
			color: ${CSSVars.white};
		}
		.links {
			a,.link {
				color: ${CSSVars.white};
			}
		}
	}

	nav {
		a {
			color: ${CSSVars.gray900};
		}
		width: 1580px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.links-divider {
			display: flex;
			align-items: center;

			.links {
				align-items: center;
				gap: 50px;
				margin-left: 50px;

				display: flex;

				.link-wrapper {
					position: relative;

					&::before {
						content: '';
						position: absolute;
						top: 100%;
						left: 0;
						width: 100%;
						height: 15px;
					}
				}
			}

			a,.link {
				color: ${CSSVars.gray900};
				font-weight: 400;
				transition: 0.2s;

				&:hover {
					color: ${CSSVars.primary500};
				}

				&.logo {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}
`;

export const StyledMiniHeader = styled.header`
	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		display: none;
	}

	nav {
		margin: 0 auto;
		height: 60px;
		padding: 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1580px;

		.logo {
			display: flex;
		}

		.hamburger {
			cursor: pointer;
			color: ${CSSVars.gray600};
			transition: 0.2s;
			margin-top: -8px;

			&.dark {
				color: ${CSSVars.white};
			}

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&.dark {
		background-color: ${CSSVars.gray700};
	}
`;

export const StyledAside = styled.aside`
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	width: 270px;
	z-index: 1000;
	background-color: ${CSSVars.white};
	padding: 25px;

	transition: 0.2s;
	transform: translateX(-270px);

	&.opened {
		transform: translateX(0);

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			transform: translateX(-270px);
		}
	}

	&.dark {
		background-color: ${CSSVars.gray700};

		.bottom {
			a {
				color: ${CSSVars.white};
			}
		}

		.content {
			nav {
				a {
					color: ${CSSVars.white};

					&.active,
					&:hover {
						background-color: ${CSSVars.gray600};
					}
				}

				.sub-items {
					.title {
						color: ${CSSVars.gray400};
					}
				}
			}
		}
	}

	display: grid;
	grid-template-rows: 50px 1fr 50px;
	grid-template-areas:
		'top'
		'content'
		'bottom';

	.top {
		grid-area: top;
		height: 100px;
	}
	.content {
		grid-area: content;
		width: 100%;
		overflow: auto;

		nav {
			display: flex;
			flex-direction: column;
			gap: 2px;
			width: 100%;

			a {
				padding: 5px 10px;
				border-radius: 5px;
				transition: 0.2s;
				color: ${CSSVars.gray900};

				&.active,
				&:hover {
					background-color: ${CSSVars.gray100};
				}
			}

			.sub-items {
				.title {
					padding-left: 10px;
					margin: 15px 0 5px;
					font-size: ${CSSVars.fontSizeXS};
					color: ${CSSVars.gray400};
					font-weight: 500;
				}

				.items {
					display: flex;
					flex-direction: column;
				}
			}
		}
	}
	.bottom {
		grid-area: bottom;
		padding: 20px 0;
		a {
			color: ${CSSVars.gray900};
		}
	}

	img {
		cursor: pointer;
	}
`;
