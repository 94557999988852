import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import Image from 'next/image';
import Link from 'next/link';
import { IPlugin } from '../../../types';
import { PopoverDiv } from './menuPopovers.styles';

interface IPopoverProps {
	isDark?: boolean;
	columns?: { title: string; apps: IPlugin[]; }[];
	show?: boolean;
}

export const AppsPopover = ({ show, isDark, columns = [] }: IPopoverProps) => {
	return (
		<PopoverDiv className={`apps ${isDark ? 'dark' : ''}  ${show ? 'show' : ''}`}>
			<div className={`apps`}>
				{columns.map((column, idx) => (
					<div className="column" key={column.title}>
						<p className="title">{column.title}</p>
						{column.apps.map((app) => (
							<Link
								className="widget"
								title={app.name}
								key={app.slug}
								href={`/widgets/${app.slug}`}
							>
								<Image src={app.logo} alt={app.name} width={20} height={20} />
								{app.name}
							</Link>
						))}
					</div>
				))}
				<div className="column more">
					<p className="title">More</p>
					<Link href={'/widgets'}>
						See All Widgets
						<IoIosArrowForward fontSize={18} className="icon" />
					</Link>
					<Link href={'/platforms'}>
						See Platforms
						<IoIosArrowForward fontSize={18} className="icon" />
					</Link>
				</div>
			</div>
		</PopoverDiv>
	);
};

export const ResourcesPopover = ({ isDark, show }: IPopoverProps) => {
	return (
		<PopoverDiv className={`${isDark ? 'dark' : ''} ${show ? 'show' : ''}`}>
			<div className={`resources  ${isDark ? 'dark' : ''}`}>
				<Link href={'/blog'}>Blog</Link>
				<Link rel="noopener noreferrer" href={'https://community.commoninja.com/'} target="_blank">
					Feature Request
				</Link>
				<Link rel="noopener noreferrer" href={'https://community.commoninja.com/'} target="_blank">
					Community Forum
				</Link>
				<Link href={'/affiliates'}>Affiliate Program</Link>
				<a href={'/platform/'}>Developers</a>
				<Link href={'/write-for-us'}>Write For Us</Link>
				<Link href={'/alternatives'}>Alternatives</Link>
				<Link href={'/built-with'}>Built With Common Ninja</Link>
				<Link
					rel="noopener noreferrer"
					href={
						'https://commoninja.site/suggest-a-widget'
					}
					target="_blank"
				>
					Suggest a Widget
				</Link>
			</div>
		</PopoverDiv>
	);
};

export const SolutionsPopover = ({ isDark, show }: IPopoverProps) => {
	return (
		<PopoverDiv className={`${isDark ? 'dark' : ''} ${show ? 'show' : ''}`}>
			<div className={`solutions ${isDark ? 'dark' : ''}`}>
				<Link href={'/solutions/saas'}>For SaaS Companies</Link>
				<Link href={'/solutions/freelancers'}>For Freelancers</Link>
				<Link href={'/solutions/agencies'}>For Agencies</Link>
				<Link href={'/solutions/business-owners'}>For Business Owners</Link>
				<Link href={'/solutions/ecommerce-stores'}>For E-Commerce Stores</Link>
				<Link href={'/solutions/publishers'}>For Publishers</Link>
			</div>
		</PopoverDiv>
	);
};
export const SupportPopover = ({ isDark, show }: IPopoverProps) => {
	return (
		<PopoverDiv className={`${isDark ? 'dark' : ''} ${show ? 'show' : ''}`}>
			<div className={`support`}>
				<Link rel="noopener noreferrer" href={'https://help.commoninja.com/hc/en-us'} target="_blank">
					Help Center
				</Link>
				<Link
					rel="noopener noreferrer"
					href={'https://www.commoninja.com/contact-us'}
					target="_blank"
				>
					Contact Us
				</Link>
			</div>
		</PopoverDiv>
	);
};
