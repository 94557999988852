import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledFooter = styled.footer`
	/* margin: 100px auto 0; */
	padding: 100px 40px 0;

	.content-wrapper {
		display: flex;
		flex-direction: column;
		max-width: 1400px;
		margin: 0 auto;
	}

	&.dark {
		background-color: ${CSSVars.gray700};

		a,
		h2 {
			color: ${CSSVars.white};
		}

		.top {
			border-bottom: 1px solid #434962;
		}

		.bottom {
			small {
				color: ${CSSVars.white};
			}
		}
	}

	a {
		display: flex;
		align-items: center;
		gap: 10px;
		font-weight: 300;
		font-size: ${CSSVars.fontSizeSM};
		color: ${CSSVars.gray900};
		max-width: 260px;
		text-align: center;
		transition: 0.2s;
		margin: 7px 0;
		min-width: 48px; // For Lighthouse

		&:hover {
			opacity: 0.7;
		}

		&.blue {
			color: ${CSSVars.primary500};
		}
		.icon {
			padding-bottom: 1px;
		}

		@media screen and (min-width: ${CSSVars.breakpointSM}) {
			text-align: left;
		}

		.icon {
			font-size: 16px;
			color: ${CSSVars.primary500};
		}
	}

	.top {
		display: flex;
		gap: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid ${CSSVars.gray200};
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		justify-content: center;

		@media screen and (min-width: ${CSSVars.breakpointSM}) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			grid-template-columns: repeat(4, 1fr);
		}

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			grid-template-columns: repeat(5, 1fr);
		}

		.column {
			display: flex;
			flex-direction: column;
			color: ${CSSVars.gray900};
			align-items: center;

			&.blog-posts {
				/* Blog footer column, cut number of lines */
				a:not(:last-child) {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				align-items: flex-start;
			}

			h2 {
				margin-bottom: 10px;
				font-weight: 600;
				font-size: ${CSSVars.fontSizeLG};

				&:not(:first-child) {
					margin-top: 30px;
				}
			}
		}
	}

	.bottom {
		display: flex;
		justify-content: space-between;
		gap: 30px;
		margin: 30px 0;
		flex-direction: column-reverse;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			flex-direction: row;
		}

		small {
			font-weight: 300;
			font-size: ${CSSVars.fontSizeSM};
			color: ${CSSVars.gray900};
			text-align: center;
		}

		.bottom-links {
			display: flex;
			text-transform: uppercase;
			justify-content: center;
			flex-wrap: wrap;
			gap: 20px;

			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				gap: 50px;
				flex-direction: row;
			}
		}
	}
`;
