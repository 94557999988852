'use client';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { StyledMiniHeader, StyledHeader, StyledAside } from './header.styles';
import { INewTag, IPlugin } from '../../types';
import {
	AppsPopover,
	ResourcesPopover,
	SolutionsPopover,
	SupportPopover,
} from './menuPopovers/menuPopovers.comp';
import { GiHamburgerMenu } from '@react-icons/all-files/gi/GiHamburgerMenu';
import { useRouter } from 'next/router';
import { ReactDimmer } from 'react-dimmer';
import { CSSProperties } from 'styled-components';
import { trackEvent } from '../../lib/track';

// @ts-ignore
import apps from 'apps.json';
// @ts-ignore
import tags from 'tags.json';

interface IHeaderProps {
	style?: CSSProperties;
	isDark?: boolean;
	isDeveloper?: boolean;
	className?: string;
	logoPath?: '/' | '';
	is404?: boolean,
}

export const Header = ({
	style = {},
	isDark = false,
	isDeveloper = false,
	className = '',
	logoPath = '',
	is404 = false,
}: IHeaderProps) => {
	const [isBrackets, setIsBrackets] = useState<boolean>(false);
	const [showAppsPopover, setShowAppsPopover] = useState<boolean>(false);
	const [showResourcesPopover, setShowResourcesPopover] =
		useState<boolean>(false);
	const [showSolutionsPopover, setShowSolutionsPopover] =
		useState<boolean>(false);
	const [showSupportPopover, setShowSupportPopover] = useState<boolean>(false);
	const [sideNavOpen, setSideNavOpen] = useState<boolean>(false);
	const router = useRouter();

	const tagList = apps.reduce((acc: string[], plugin: IPlugin) => {
		(plugin.newTags || []).forEach((tag) => {
			const wantedTag = (tags as INewTag[]).find((t) => t._id === tag._id);
			if (wantedTag && !acc.includes(wantedTag.name)) {
				acc.push(wantedTag.name);
			}
		});
		return acc;
	}, []);

	const columnItems = tagList.splice(0, 3).map((tag: string) => {
		const wantedApps = apps
			.filter((p: IPlugin) => ((p.newTags || []).map((t) => t.name).includes(tag)))
			.slice(0, 5);

		return { title: tag, apps: wantedApps };
	});

	function renderLogo() {
		if (isDark || is404) {
			return (
				<Image
					src={'/assets/cn-logo-dark.svg'}
					alt="Common ninja logo"
					width={200}
					height={30}
				/>
			);
		}

		return (
			<Image
				src={'/assets/cn-logo-light.svg'}
				alt="Common ninja logo"
				width={200}
				height={30}
			/>
		);
	}

	// content={<AppsPopover columns={columns} isDark={isDark} show={showAppsPopover} setShow={setShowAppsPopover} />}
	function renderLinks() {
		return (
			<div className="links">
				<div
					className="link-wrapper"
					onMouseEnter={() => setShowAppsPopover(true)}
					onMouseLeave={() => setShowAppsPopover(false)}
				>
					<div>
						<Link href="/widgets">Widgets</Link>
						<AppsPopover
							show={showAppsPopover}
							columns={columnItems}
							isDark={isDark}
						/>
					</div>
				</div>
				<div>
					<Link href={isBrackets ? '/widgets/brackets/pricing' : '/pricing'}>
						Pricing
					</Link>
				</div>
				<div
					className="link-wrapper"
					onMouseEnter={() => setShowSolutionsPopover(true)}
					onMouseLeave={() => setShowSolutionsPopover(false)}
				>
					<div>
						<Link href="/solutions">Solutions</Link>
						<SolutionsPopover show={showSolutionsPopover} isDark={isDark} />
					</div>
				</div>
				<div
					className="link-wrapper"
					onMouseEnter={() => setShowResourcesPopover(true)}
					onMouseLeave={() => setShowResourcesPopover(false)}
				>
					<div>
						<Link href="/resources">Resources</Link>
						{/* <p className="link">Resources</p> */}
						<ResourcesPopover show={showResourcesPopover} isDark={isDark} />
					</div>
				</div>
				<div
					className="link-wrapper"
					onMouseEnter={() => setShowSupportPopover(true)}
					onMouseLeave={() => setShowSupportPopover(false)}
				>
					<div>
						<Link href="/support">Support</Link>
						{/* <p className="link">Support</p> */}
						<SupportPopover show={showSupportPopover} isDark={isDark} />
					</div>
				</div>
			</div>
		);
	}

	function renderSideNav() {
		return (
			<>
				<StyledMiniHeader
					style={style}
					className={`${isDark ? 'dark' : ''} ${className}`}
				>
					<nav>
						<Link
							href={isDark && !logoPath ? '/developers' : '/'}
							className="logo"
						>
							{renderLogo()}
						</Link>
						<GiHamburgerMenu
							onClick={() => setSideNavOpen(true)}
							fontSize={23}
							className={`hamburger ${isDark ? 'dark' : ''}`}
						/>
					</nav>
				</StyledMiniHeader>
				<StyledAside
					className={`${isDark ? 'dark' : ''} ${sideNavOpen ? 'opened' : ''}`}
				>
					<div className="top">
						<Link
							onClick={() => setSideNavOpen(false)}
							href={isDark && !logoPath ? '/developers' : '/'}
						>
							{renderLogo()}
						</Link>
					</div>
					<div className="content">
						<nav>
							<Link
								className={`${router.pathname === '/widgets' ? 'active' : ''}`}
								onClick={() => setSideNavOpen(false)}
								href="/widgets"
							>
								Widgets
							</Link>
							<Link
								className={`${router.pathname === '/platforms' ? 'active' : ''
									}`}
								onClick={() => setSideNavOpen(false)}
								href="/platforms"
							>
								Platforms
							</Link>
							<Link
								className={`${router.pathname === '/pricing' ? 'active' : ''}`}
								onClick={() => setSideNavOpen(false)}
								href={isBrackets ? '/widgets/brackets/pricing' : '/pricing'}
							>
								Pricing
							</Link>
							<div className="sub-items">
								<p className="title">Resources</p>
								<div className="items">
									<Link
										className={`${router.pathname === '/blog' ? 'active' : ''}`}
										onClick={() => setSideNavOpen(false)}
										href="/blog"
									>
										Blog
									</Link>
									<Link
										rel="noopener noreferrer"
										href="https://docs.commoninja.com/common-ninja"
										target={'_blank'}
									>
										Docs
									</Link>
									<Link
										rel="noopener noreferrer"
										href="https://community.commoninja.com/"
										target={'_blank'}
									>
										Community Forum
									</Link>
									<Link
										rel="noopener noreferrer"
										href="https://community.commoninja.com/"
										target={'_blank'}
									>
										Feature Request
									</Link>
									<Link
										className={`${router.pathname === '/affiliates' ? 'active' : ''
											}`}
										onClick={() => setSideNavOpen(false)}
										href="/affiliates"
									>
										Affiliate Program
									</Link>
									<Link
										className={`${router.pathname === '/write-for-us' ? 'active' : ''
											}`}
										onClick={() => setSideNavOpen(false)}
										href="/write-for-us"
									>
										Write for us
									</Link>
									<Link href={'/alternatives'}>Alternatives</Link>
									<Link
										rel="noopener noreferrer"
										href={
											'https://commoninja.site/suggest-a-widget'
										}
										target="_blank"
									>
										Suggest a Widget
									</Link>
								</div>
							</div>
							<div className="sub-items">
								<p className="title">Support</p>
								<div className="items">
									<Link
										rel="noopener noreferrer"
										className={`${router.pathname === '/' ? '' : ''}`}
										href="https://help.commoninja.com/hc/en-us"
										target={'_blank'}
									>
										Help Center
									</Link>
									<Link
										rel="noopener noreferrer"
										className={`${router.pathname === '/' ? '' : ''}`}
										href="https://www.commoninja.com/contact-us"
										target={'_blank'}
									>
										Contact Us
									</Link>
								</div>
							</div>
						</nav>
					</div>
					<div className="bottom">
						<Link
							href="/login"
							onClick={() => {
								trackEvent('Website Login');
							}}
						>
							Log In
						</Link>
					</div>
				</StyledAside>
			</>
		);
	}

	useEffect(() => {
		if (
			typeof window !== 'undefined' &&
			window.location.pathname.includes('brackets')
		) {
			setIsBrackets(true);
		} else {
			setIsBrackets(false);
		}
	}, []);

	return (
		<>
			<ReactDimmer isOpen={sideNavOpen} exitDimmer={setSideNavOpen} blur={1} />
			{renderSideNav()}
			<StyledHeader style={style} className={`${isDark ? 'dark' : ''}`}>
				<nav>
					<div className="links-divider">
						<Link href={'/'} className="logo">
							{renderLogo()}
						</Link>
						{renderLinks()}
					</div>
					<div className="links-divider">
						<Link
							className="login"
							href={isDeveloper ? '/developer/login' : '/login'}
							onClick={() => {
								trackEvent('Website Login');
							}}
						>
							Log In
						</Link>
					</div>
				</nav>
			</StyledHeader>
		</>
	);
};
