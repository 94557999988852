import type { AppProps } from 'next/app';
import { ToastContainer } from 'react-toastify';
import { ParallaxProvider } from 'react-scroll-parallax';
import Script from 'next/script';
import { Layout } from '../components/layout/layout.comp';
import { GlobalStyles } from '../styles/styles.config';
import Head from 'next/head';

import 'react-toastify/dist/ReactToastify.css';

export default function App({ Component, pageProps }: AppProps) {
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			{/* GTM */}
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WJGB8PP');`,
				}}
			></Script>
			{/* GTM */}
			<Script
				id="mixpabel-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `(function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
                  for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
                  MIXPANEL_CUSTOM_LIB_URL:"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
                  mixpanel.init("49aed1723e321fc7cb57fe4b906a20f5", {batch_requests: true});`,
				}}
			></Script>
			{/* Ref, source, etc */}
			<Script
				id="ref-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					// Get referrer, source, UTM parameters, etc and store in cookie
					__html: `
            (function() {
              // Function to check if a specific cookie exists
              function doesCookieExist(name) {
                  return document.cookie.split(';').some(function(item) {
                      return item.trim().indexOf(name + '=') == 0;
                  });
              }
          
              // Function to create a flat object from URLSearchParams
              function getQueryParams() {
                  var params = new URLSearchParams(window.location.search);
                  var queryParams = {};
                  for (const [key, value] of params.entries()) {
                      queryParams[key] = value;
                  }
                  return queryParams;
              }
          
              // Check if the 'cn-u-d' cookie already exists
              if (!doesCookieExist('cn-u-d')) {
                  // Get the referrer
                  var referrer = document.referrer || 'direct';
          
                  // Get the query parameters
                  var queryParams = getQueryParams();
          
                  // Create a flat object with referrer and query parameters
                  var userData = Object.assign({ referrer: referrer, 'landing-page': (window.location.href || '').split('?')[0] }, queryParams);
          
                  // Store the object in a cookie named 'cn-u-d'
                  document.cookie = "cn-u-d=" + encodeURIComponent(JSON.stringify(userData)) + ";path=/;";
              }
            })();
          `,
				}}
			/>
			{/* <Script
        id="profitwell-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          try {
            profitwell('start', {});
          } catch(e) {}`,
        }}
      ></Script> */}
			<GlobalStyles />
			<ParallaxProvider>
				<Layout>
					<Component {...pageProps} />
					<ToastContainer />
				</Layout>
			</ParallaxProvider>
		</>
	);
}
